<template>
  <v-container>
    <v-dialog
      v-model="dialog"
      max-width="1200px"
      persistent
    >
      <v-card>
        <v-card-title>
          Detalles Tabla - {{viewedTable.name}}
        </v-card-title>
        <v-card-text>
          <v-data-table
            :items="viewedTable.rows"
            :headers="headerDetalles"
          >
            <template v-slot:[`item.nullable`]="{ item }">
              {{item.nullable ? "Si" : "No" }}
            </template>
            <template v-slot:[`item.is_primary_key`]="{ item }">
              <v-container
                class="pa-0 ma-0"
                fluid
                style="text-align-last: center;"
              >
                <v-switch
                  v-model="item.is_primary_key"
                  :label="`${item.is_primary_key ? 'Si' : 'No'}`"
                  @click="changePrimaryKey(item)"
                ></v-switch>
              </v-container>
            </template>
            <template v-slot:[`item.updated`]="{ item }">
              {{item.updated ? "Si" : "No" }}
            </template>
            <template v-slot:[`item.deleted`]="{ item }">
              {{item.deleted ? "Si" : "No" }}
            </template>
        
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green"
            text
            @click="closeDialog"
          >
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-col cols="12">
      <p class="ma-0">Gateway</p>
      <v-autocomplete
        v-model="id_gateway"
        :items="gateways"
        item-value="id_gateway"
        item-text="name"
        :disabled="loadingGateway"
        :loading="loadingGateway"
        @change="getTables(id_gateway)"
        outlined
        dense
        hide-details
      ></v-autocomplete>
    </v-col>
    <v-col cols="12">
      <v-data-table
        :headers="headers"
        :items="tables"
      >
        <template v-slot:[`item.create`]="{ item }">
          {{item.create ? "Si" : "No" }}
          <template v-if="!item.create">
            <br>
            <br>
            <v-btn
              small
              color="green"
              class="white--text"
              @click="createTable(item)"
              :loading="item.loading"
              :disabled="item.lo"
            >Crear Tabla</v-btn>
            <br>
            <br>
          </template>
        </template>
        <template v-slot:[`item.sync`]="{ item }">
          <v-container
            v-if="item.create"
            class="pa-0 ma-0"
            fluid
          >
            <v-row class="pa- ma-0">
              <v-col cols="4"></v-col>
              <v-col cols="4">
                <v-switch
                  v-model="item.sync"
                  :label="`${item.sync ? 'Si' : 'No'}`"
                  @click="changeSync(item)"
                ></v-switch>
              </v-col>
              <v-col cols="4"></v-col>
            </v-row>
          </v-container>
          <div
            v-else
          >
            N/D
          </div>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            small
            class="mr-2"
            @click="openDialog(item)"
          >
            mdi-eye
          </v-icon>
        </template>
      </v-data-table>
    </v-col>
  </v-container>
</template>

<script>
import axios from 'axios'

export default {
  components:{
  },
  data: () => ({
    loading: false,
    headers: [
      { text: 'Tabla', value: 'name', align: 'center' },
      { text: '¿Creada?', value: 'create', align: 'center' },
      { text: '¿Sincronizar?', value: 'sync', align: 'center' },
      { text: 'Número de Registros', value: 'num_records', align: 'center' },
      { text: 'Acciones', value: 'actions', align: 'center', sortable: false }
    ],
    headerDetalles: [
      { text: 'Columna', value: 'name', align: 'center' },
      { text: 'Firebird - Tipo Dato', value: 'data_type', align: 'center' },
      { text: 'MySQL - Tipo Dato', value: 'data_type_mysql', align: 'center' },
      { text: '¿Nullable?', value: 'nullable', align: 'center' },
      { text: 'Llave Primaria', value: 'is_primary_key', align: 'center' },
      { text: '¿Actualizo?', value: 'updated', align: 'center' },
      { text: '¿Se elimino?', value: 'deleted', align: 'center' }
    ],
    tables: [],
    loadingGateway: false,
    gateways: [],
    id_gateway: -1,
    defaultTable:{
      id_table: -1,
      name: "",
      create: false,
      sync: false,
      rows: []
    },
    viewedTable:{
      id_table: -1,
      name: "",
      create: false,
      sync: false,
      rows: []
    },
    dialog: false
  }),
  mounted() {
    this.getGateways();
  },
  methods: {
    getGateways(){
      this.loadingGateway = true;
      axios
      .get('/gateway/list')
      .then(response => {
        this.gateways = response.data;
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        this.loadingGateway = false;
      })
    },
    getTables(id_gateway){
      this.loading = true;
      axios
      .get("/table/list/"+id_gateway)
      .then(response => {
        this.tables = response.data.map(t => {
          return {
            ...t,
            loading: false,
          }
        })
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        this.loading = false;
      })
    },
    openDialog(item){
      this.viewedTable = item;
      this.dialog = true;
    },
    closeDialog(){
      this.viewedTable = Object.assign({}, this.defaultTable);
      this.dialog = false;
    },
    changeSync(item){
      axios
      .post(`/table/SetSync/${item.id_table}/${item.sync}`)
      .then(() => {
      })
      .catch(error => {
        console.log(error);
      })
    },
    changePrimaryKey(item){
      axios
      .post(`/table/SetPrimaryKey/${item.id_row}/${item.is_primary_key}`)
      .then(() => {
      })
      .catch(error => {
        console.log(error);
      })
    },
    createTable(item){
      item.loading = true;
      axios
      .post(`/table/CreateTable/${item.id_table}`)
      .then(() => {
        item.create = true;
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        item.loading = false;
      })
    }
  }
}
</script>