import { VCard } from 'vuetify/lib/components/VCard';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,{attrs:{"justify-sm":"center"}},[_c(VCard,{staticClass:"mt-4",attrs:{"width":"95%","fill":""}},[_c(VTabs,{attrs:{"background-color":"blue darken-2","grow":"","dark":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c(VTab,{key:"reports"},[_vm._v(" Reportes ")]),_c(VTab,{key:"catalogos"},[_vm._v(" Control de Activos ")]),_c(VTab,{key:"gateway"},[_vm._v(" Gateways ")]),_c(VTab,{key:"tables"},[_vm._v(" Base de Datos ")])],1),_c(VTabsItems,{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c(VTabItem,{key:"reports"},[_c('reports')],1),_c(VTabItem,{key:"catalogos"},[_c('catalogos')],1),_c(VTabItem,{key:"gateway"},[_c('gateway')],1),_c(VTabItem,{key:"tables"},[_c('tables')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }