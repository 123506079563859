import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,{staticClass:"mb-6 ma-0 pa-0",attrs:{"no-gutters":""}},[_c(VSnackbar,{attrs:{"top":"","color":"red"},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" Nombre de Usuario y/o Contraseña Incorrectos ")]),(_vm.show)?_c(VCol,{attrs:{"lg":_vm.cols[0],"md":6,"sm":_vm.cols[0]}},[_c(VImg,{staticClass:"img-responsive",attrs:{"src":"https://source.unsplash.com/600x960/?medical,lab","max-height":_vm.maxHeight}})],1):_vm._e(),_c(VCol,{staticClass:"align-self-center",attrs:{"lg":_vm.cols[1],"md":6,"sm":_vm.cols[1]}},[_c(VRow,{staticClass:"justify-center"},[_c(VCol,{staticClass:"text-center",attrs:{"cols":"8","md":"8","sm":"8"}},[_c(VImg,{attrs:{"src":"/img/logotipo-600x100.png","max-height":"75px","max-width":"450px"}})],1),_c(VCol,{attrs:{"cols":"8","md":"8","sm":"8"}},[_c(VTextField,{attrs:{"rules":[_vm.rules.required, _vm.rules.min6],"label":"Nombre de Usuario","hint":"Al menos 6 caracteres","counter":""},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}})],1),_c(VCol,{attrs:{"cols":"8","md":"8","sm":"8"}},[_c(VTextField,{attrs:{"append-icon":_vm.show1 ? 'mdi-eye' : 'mdi-eye-off',"rules":[_vm.rules.required, _vm.rules.min8],"type":_vm.show1 ? 'text' : 'password',"label":"Contraseña","hint":"Al menos 8 caracteres","counter":""},on:{"click:append":function($event){_vm.show1 = !_vm.show1},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.login.apply(null, arguments)}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1),_c(VCol,{attrs:{"cols":"8","md":"8","sm":"8"}},[_c(VSpacer),_c(VBtn,{attrs:{"dark":"","block":"","align-center":"","justify-center":"","color":"primary"},on:{"click":_vm.login}},[_vm._v(" Iniciar Sesion ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }