import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,{attrs:{"justify-sm":"center"}},[_c(VCol,{attrs:{"cols":"12"}},[_c(VCard,{staticClass:"mt-4",attrs:{"width":"100%","fill":""}},[_c(VTabs,{attrs:{"background-color":"white","grow":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c(VTab,{key:"report-1"},[_vm._v(" Clientes ")])],1),_c(VTabsItems,{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c(VTabItem,{key:"report-1"},[_c(VContainer,{attrs:{"fluid":"","grid-list-xl":""}},[_c(VLayout,{attrs:{"wrap":""}},[_c('iframe',{attrs:{"id":"info","width":"100%","height":"920","src":"https://app.powerbi.com/reportEmbed?reportId=bcc1fa00-d5d5-40ad-9287-fea1df07897e&autoAuth=true&ctid=46feded0-8875-4f66-b900-ceb5282de5f0","frameborder":"0","allowFullScreen":"true"}})])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }