<template>
  <v-container>
    <v-dialog
      max-width="65%"
      v-model="dialogAddEdit"
      persistent
    >
      <v-card>
        <v-card-title>
          {{ editedItem.id_maquina === -1 ? "Crear" : "Editar" }} Maquina
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="editedItem.marca"
                label="Marca"
                outlined
                :error="errores.marca"
                :error-messages="errores.marca ? 'El campo marca es requerido.' : null"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="editedItem.modelo"
                label="Modelo"
                outlined
                :error="errores.modelo"
                :error-messages="errores.modelo ? 'El campo modelo es requerido.' : null"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green" text @click="closeDialogAddEdit">Cancelar</v-btn>
          <v-btn color="red" text @click="saveDialogAddEdit" :loading="loadingSave">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      max-width="65%"
      v-model="dialogDelete"
      persistent
    >
      <v-card>
        <v-card-title>
          Eliminar Maquina
        </v-card-title>
        <v-card-text>
          ¿Está seguro de que desea eliminar la maquina con marca "{{ editedItem.marca }}" y modelo "{{ editedItem.modelo }}"? Esta acción es permanente y no se puede revertir.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green" text @click="closeDialogDelete">Cancelar</v-btn>
          <v-btn color="red" text @click="saveDialogDelete" :loading="loadingDelete">Eliminar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row>
      <v-col cols="12">
        <v-btn
          color="primary"
          @click="openDialogAddEdit(null)"
        >
          Crear Maquina
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :items="items"
          :headers="headers"
          :loading="loading"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              small
              class="mr-2"
              @click="openDialogAddEdit(item)"
            >
              mdi-pencil
            </v-icon>
            <v-icon
              small
              class="mr-2"
              @click="openDialogDelete(item)"
            >
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios'

export default {
  data: () => ({
    dialogAddEdit: false,
    loading: false,
    items: [],
    headers:[
      {
        text: "Marca",
        value: "marca",
        align: "center",
        width: "45%"
      },
      {
        text: "Modelo",
        value: "modelo",
        align: "center",
        width: "45%"
      },
      {
        text: "Acciones",
        value: "actions",
        align: "center",
        width: "10%"
      }
    ],
    editedItem: {
      id_maquina: -1,
      marca: "",
      modelo: ""
    },
    defaultEditedItem: {
      id_maquina: -1,
      marca: "",
      modelo: ""
    },
    loadingSave: false,
    errores:{
      marca: false,
      modelo: false
    },
    defaultErrores: {
      marca: false,
      modelo: false
    },
    dialogDelete: false,
    loadingDelete: false
  }),
  mounted() {
    this.getItems();
  },
  methods: {
    getItems(){
      this.loading = true;
      axios
      .get('/CatalogoActivos/ListarMaquinas')
      .then(response => {
        this.items = response.data;
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.loading = false;
      })
    },
    openDialogAddEdit(editItem = null){
      if(editItem != null) this.editedItem = Object.assign({}, editItem);
      else this.editedItem = Object.assign({}, this.defaultEditedItem);
      this.errores = Object.assign({}, this.defaultErrores);
      this.dialogAddEdit = true;
    },
    closeDialogAddEdit(){
      this.editedItem = Object.assign({}, this.defaultEditedItem);
      this.errores = Object.assign({}, this.defaultErrores);
      this.dialogAddEdit = false;
    },
    saveDialogAddEdit(){
      this.errores = Object.assign({}, this.defaultErrores);

      if (this.editedItem.marca.length === 0) {
        this.errores.marca = true;
        return;
      }

      if (this.editedItem.modelo.length === 0) {
        this.errores.modelo = true;
        return;
      }

      this.loadingSave = true;

      axios({
        method: this.editedItem.id_maquina === -1 ? 'POST' : 'PUT',
        url: this.editedItem.id_maquina === -1 ? '/CatalogoActivos/CrearMaquina' : `/CatalogoActivos/EditarMaquina/${this.editedItem.id_maquina}`,
        data: {
          marca: this.editedItem.marca,
          modelo: this.editedItem.modelo
        }
      })
      .then(() => {
        this.getItems();
        this.closeDialogAddEdit();
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.loadingSave = false;
      });
    },
    openDialogDelete(editItem){
      this.editedItem = Object.assign({}, editItem);
      this.dialogDelete = true;
    },
    closeDialogDelete(){
      this.editedItem = Object.assign({}, this.defaultEditedItem);
      this.dialogDelete = false;
    },
    saveDialogDelete(){
      this.loadingDelete = true;
      axios
      .delete(`/CatalogoActivos/EliminarMaquina/${this.editedItem.id_maquina}`)
      .then(() => {
        this.closeDialogDelete();
        this.getItems();
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.loadingDelete = false;
      });
    }
  }
}
</script>
