import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VToolbar,{attrs:{"color":"white"}},[_c(VToolbarTitle,[_c(VImg,{attrs:{"src":"/img/logotipo-600x100.png","max-width":"130px","max-height":"50px"}})],1),_c(VSpacer),_c(VBtn,{attrs:{"icon":""},on:{"click":_vm.logout}},[_c(VIcon,[_vm._v("mdi-power")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }