<template>
    <v-row class="ma-0 pa-0">
      <v-col cols="12" class="pa-0 ma-0">
        <v-tabs vertical>
          <v-tab>
            Asignacion
          </v-tab>
          <v-tab>
            Clientes
          </v-tab>
          <v-tab>
            Maquinas
          </v-tab>
          <v-tab>
            Equipos
          </v-tab>
          <v-tab>
            Lineas
          </v-tab>
          <v-tab>
            Propietarios
          </v-tab>
          <v-tab>
            Status
          </v-tab>
          <v-tab>
            Presupuestos
          </v-tab>
          <v-tab-item>
            <asignacion></asignacion>
          </v-tab-item>
          <v-tab-item>
            <cliente></cliente>
          </v-tab-item>
          <v-tab-item>
            <maquinas></maquinas>
          </v-tab-item>
          <v-tab-item>
            <equipos></equipos>
          </v-tab-item>
          <v-tab-item>
            <lineas></lineas>
          </v-tab-item>
          <v-tab-item>
            <propietarios></propietarios>
          </v-tab-item>
          <v-tab-item>
            <status></status>
          </v-tab-item>
          <v-tab-item>
            <presupuestos></presupuestos>
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
</template>

<script>
import Asignacion from './Catalogos/Asignacion.vue'
import Cliente from './Catalogos/Clientes.vue'
import Equipos from './Catalogos/Equipos.vue'
import Lineas from './Catalogos/Lineas.vue'
import Propietarios from './Catalogos/Propietarios.vue'
import Status from './Catalogos/Status.vue'
import Maquinas from './Catalogos/Maquinas.vue'
import Presupuestos from './Catalogos/Presupuestos.vue'


export default {
  components:{
    Asignacion,
    Cliente,
    Equipos,
    Lineas,
    Propietarios,
    Status,
    Maquinas,
    Presupuestos
  },
  data: () => ({
  }),
  mounted() {
  },
  methods: {
  }
}
</script>