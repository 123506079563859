import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VDialog,{attrs:{"max-width":"1200px","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,[_vm._v(" Detalles Tabla - "+_vm._s(_vm.viewedTable.name)+" ")]),_c(VCardText,[_c(VDataTable,{attrs:{"items":_vm.viewedTable.rows,"headers":_vm.headerDetalles},scopedSlots:_vm._u([{key:"item.nullable",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.nullable ? "Si" : "No")+" ")]}},{key:"item.is_primary_key",fn:function(ref){
var item = ref.item;
return [_c(VContainer,{staticClass:"pa-0 ma-0",staticStyle:{"text-align-last":"center"},attrs:{"fluid":""}},[_c(VSwitch,{attrs:{"label":("" + (item.is_primary_key ? 'Si' : 'No'))},on:{"click":function($event){return _vm.changePrimaryKey(item)}},model:{value:(item.is_primary_key),callback:function ($$v) {_vm.$set(item, "is_primary_key", $$v)},expression:"item.is_primary_key"}})],1)]}},{key:"item.updated",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.updated ? "Si" : "No")+" ")]}},{key:"item.deleted",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.deleted ? "Si" : "No")+" ")]}}],null,true)})],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"green","text":""},on:{"click":_vm.closeDialog}},[_vm._v(" Cerrar ")])],1)],1)],1),_c(VCol,{attrs:{"cols":"12"}},[_c('p',{staticClass:"ma-0"},[_vm._v("Gateway")]),_c(VAutocomplete,{attrs:{"items":_vm.gateways,"item-value":"id_gateway","item-text":"name","disabled":_vm.loadingGateway,"loading":_vm.loadingGateway,"outlined":"","dense":"","hide-details":""},on:{"change":function($event){return _vm.getTables(_vm.id_gateway)}},model:{value:(_vm.id_gateway),callback:function ($$v) {_vm.id_gateway=$$v},expression:"id_gateway"}})],1),_c(VCol,{attrs:{"cols":"12"}},[_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.tables},scopedSlots:_vm._u([{key:"item.create",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.create ? "Si" : "No")+" "),(!item.create)?[_c('br'),_c('br'),_c(VBtn,{staticClass:"white--text",attrs:{"small":"","color":"green","loading":item.loading,"disabled":item.lo},on:{"click":function($event){return _vm.createTable(item)}}},[_vm._v("Crear Tabla")]),_c('br'),_c('br')]:_vm._e()]}},{key:"item.sync",fn:function(ref){
var item = ref.item;
return [(item.create)?_c(VContainer,{staticClass:"pa-0 ma-0",attrs:{"fluid":""}},[_c(VRow,{staticClass:"pa- ma-0"},[_c(VCol,{attrs:{"cols":"4"}}),_c(VCol,{attrs:{"cols":"4"}},[_c(VSwitch,{attrs:{"label":("" + (item.sync ? 'Si' : 'No'))},on:{"click":function($event){return _vm.changeSync(item)}},model:{value:(item.sync),callback:function ($$v) {_vm.$set(item, "sync", $$v)},expression:"item.sync"}})],1),_c(VCol,{attrs:{"cols":"4"}})],1)],1):_c('div',[_vm._v(" N/D ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c(VIcon,{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.openDialog(item)}}},[_vm._v(" mdi-eye ")])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }