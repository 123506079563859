<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="90%"
  >
    <v-card>
      <v-card-title>
        Sucursales de {{ cliente.nombre }}
      </v-card-title>
      <v-card-text>
        <v-dialog
          max-width="65%"
          v-model="dialogAddEdit"
          persistent
        >
          <v-card>
            <v-card-title>
              {{ editedItem.id_sucursal === -1 ? "Crear" : "Editar" }} Sucursal
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="6">
                  <v-autocomplete
                    v-model="editedItem.id_entidad"
                    label="Entidad"
                    item-value="id_entidad"
                    item-text="entidad"
                    :items="entidades"
                    outlined
                    :error="errores.id_entidad"
                    :error-messages="errores.id_entidad ? 'Debe de indicar una entidad para la sucursal.' : null"
                    @change="getMunicipios"
                    :loading="loadingEntidades"
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="6">
                  <v-autocomplete
                    v-model="editedItem.id_municipio"
                    label="Municipio"
                    item-value="id_municipio"
                    item-text="municipio"
                    :items="municipios"
                    outlined
                    :error="errores.id_municipio"
                    :error-messages="errores.id_municipio ? 'Debe de indicar un municipio para la sucursal.' : null"
                    :loading="loadingMunicipios"
                    :disabled="editedItem.id_entidad == -1"
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="editedItem.codigo"
                    label="Nombre"
                    outlined
                    :error="errores.codigo"
                    :error-messages="errores.codigo ? 'El codigo de la sucursal debe tener entre 1 y 50 caracteres.' : null"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="editedItem.nombre"
                    label="Nombre"
                    outlined
                    :error="errores.nombre"
                    :error-messages="errores.nombre ? 'El nombre de la sucursal debe tener entre 1 y 256 caracteres.' : null"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="green" text @click="closeDialogAddEdit">Cancelar</v-btn>
              <v-btn color="red" text @click="saveDialogAddEdit" :loading="loadingSave">Guardar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
          max-width="65%"
          v-model="dialogDelete"
          persistent
        >
          <v-card>
            <v-card-title>
              Eliminar Sucursal
            </v-card-title>
            <v-card-text>
              ¿Está seguro que desea eliminar la sucursal llamada "{{ editedItem.nombre }}"? Esta acción es permanente y no se puede revertir.
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="green" text @click="closeDialogDelete">Cancelar</v-btn>
              <v-btn color="red" text @click="saveDialogDelete" :loading="loadingDelete">Eliminar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-row>
          <v-col cols="12">
            <v-btn
              color="primary"
              @click="openDialogAddEdit(null)"
            >
              Crear Sucursal
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-data-table
              :items="items"
              :headers="headers"
              :loading="loading"
            >
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon
                  small
                  class="mr-2"
                  @click="openDialogAddEdit(item)"
                >
                  mdi-pencil
                </v-icon>
                <v-icon
                  small
                  class="mr-2"
                  @click="openDialogDelete(item)"
                >
                  mdi-delete
                </v-icon>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="green"
          text
          @click="closeDialog"
        >
          Cerrar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios'

export default {
  data() {
    return {
      dialog: false,
      dialogAddEdit: false,
      loading: false,
      items: [],
      headers: [
        {
          text: "Código",
          value: "codigo",
          align: "center",
          width: "22.5%",
        },
        {
          text: "Sucursal",
          value: "nombre",
          align: "center",
          width: "22.5%",
        },
        {
          text: "Entidad",
          value: "entidad",
          align: "center",
          width: "22.5%",
        },
        {
          text: "Municipio",
          value: "municipio",
          align: "center",
          width: "22.5%",
        },
        {
          text: "Acciones",
          value: "actions",
          align: "center",
          width: "10%",
        },
      ],
      editedItem: {
        id_sucursal: -1,
        nombre: "",
        codigo: "",
      },
      defaultEditedItem: {
        id_sucursal: -1,
        nombre: "",
        codigo: "",
      },
      loadingSave: false,
      errores: {
        nombre: false,
        codigo: false,
      },
      defaultErrores: {
        nombre: false,
        codigo: false,
      },
      dialogDelete: false,
      loadingDelete: false,
      cliente: {
        id_cliente: -1,
        nombre: ""
      },
      defaultCliente: {
        id_cliente: -1,
        nombre: ""
      },
      entidades: [],
      loadingEntidades: false,
      municipios: [],
      loadingMunicipios: false
    };
  },
  
  methods: {
    openDialog(cliente) {
      this.cliente = Object.assign({}, cliente);
      this.getItems();
      this.getEntidades();
      this.dialog = true;
    },
    closeDialog(){
      this.cliente = Object.assign({}, this.defaultCliente);
      this.items = [];
      this.dialog = false;
    },
    getItems() {
      this.loading = true;
      console.log(this.cliente);
      axios
        .get(`/CatalogoActivos/ListarSucursales/${this.cliente.id_cliente}`)
        .then((response) => {
          this.items = response.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getEntidades() {
      this.loadingEntidades = true;
      axios
        .get(`/CatalogoActivos/ListarEntidades`)
        .then((response) => {
          this.entidades = response.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loadingEntidades = false;
        });
    },
    getMunicipios(id_entidad) {
      this.loadingMunicipios = true;
      axios
        .get(`/CatalogoActivos/ListarMunicipios/${id_entidad}`)
        .then((response) => {
          this.municipios = response.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loadingMunicipios = false;
        });
    },
    openDialogAddEdit(editItem = null) {
      if (editItem !== null) this.editedItem = Object.assign({}, editItem);
      else this.editedItem = Object.assign({}, this.defaultEditedItem);
      this.errores = Object.assign({}, this.defaultErrores);
      this.dialogAddEdit = true;
      this.getMunicipios(editItem.id_entidad);
    },
    closeDialogAddEdit() {
      this.editedItem = Object.assign({}, this.defaultEditedItem);
      this.errores = Object.assign({}, this.defaultErrores);
      this.dialogAddEdit = false;
    },
    saveDialogAddEdit() {
      this.errores = Object.assign({}, this.defaultErrores);

      if (this.editedItem.nombre.length === 0 || this.editedItem.nombre.length > 256) {
        this.errores.nombre = true;
        return;
      }

      if (this.editedItem.codigo.length === 0 || this.editedItem.codigo.length > 256) {
        this.errores.codigo = true;
        return;
      }

      if (this.editedItem.id_entidad == -1) {
        this.errores.id_entidad = true;
        return;
      }

      if (this.editedItem.id_municipio == -1) {
        this.errores.id_municipio = true;
        return;
      }
      
      this.loadingSave = true;

      axios({
        method: this.editedItem.id_sucursal === -1 ? 'POST' : 'PUT',
        url: this.editedItem.id_sucursal === -1 ? '/CatalogoActivos/CrearSucursal' : `/CatalogoActivos/EditarSucursal/${this.editedItem.id_sucursal}`,
        data: {
          id_entidad: this.editedItem.id_entidad,
          id_municipio: this.editedItem.id_municipio,
          id_cliente: this.cliente.id_cliente,
          nombre: this.editedItem.nombre,
          codigo: this.editedItem.codigo,
        },
      })
        .then(() => {
          this.getItems();
          this.closeDialogAddEdit();
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loadingSave = false;
        });
    },
    openDialogDelete(editItem) {
      this.editedItem = Object.assign({}, editItem);
      this.dialogDelete = true;
    },
    closeDialogDelete() {
      this.editedItem = Object.assign({}, this.defaultEditedItem);
      this.dialogDelete = false;
    },
    saveDialogDelete() {
      this.loadingDelete = true;
      axios
        .delete(`/CatalogoActivos/EliminarSucursal/${this.editedItem.id_sucursal}`)
        .then(() => {
          this.closeDialogDelete();
          this.getItems();
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loadingDelete = false;
        });
    },
  },
};
</script>
