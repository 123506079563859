<template>
  <v-row justify-sm="center">
    <v-card 
      class="mt-4"
      width="95%"
      fill
    >
      <v-tabs
          background-color="blue darken-2"
          grow
          dark
          v-model="tab"
        >
          <v-tab key="reports">
            Reportes
          </v-tab>

          <v-tab key="catalogos">
            Control de Activos
          </v-tab>

          <v-tab key="gateway">
            Gateways
          </v-tab>

          <v-tab key="tables">
            Base de Datos
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item key="reports" >
            <reports></reports>
          </v-tab-item>

          <v-tab-item key="catalogos" >
            <catalogos></catalogos>
          </v-tab-item>

          <v-tab-item key="gateway" >
            <gateway></gateway>
          </v-tab-item>

          <v-tab-item key="tables" >
            <tables></tables>
          </v-tab-item>

        </v-tabs-items>
    </v-card>
  </v-row>
</template>

<script>
//import axios from 'axios'
import Gateway from './Gateway.vue'
import Tables from './Tables.vue'
import Reports from './Reports.vue'
import Catalogos from './Catalogos.vue'


export default {
  components:{
    Gateway,
    Tables,
    Reports,
    Catalogos
  },
  data: () => ({
    tab: null,
  }),
  mounted() {

  },
  methods: {
  }
}
</script>