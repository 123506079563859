<template>
  <v-row justify-sm="center">
    <v-col cols="12">
      <v-card 
      class="mt-4"
      width="100%"
      fill
    >
      <v-tabs
        background-color="white"
        grow
        v-model="tab"
      >
        <v-tab key="report-1">
          Clientes
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item key="report-1" >
          <v-container fluid grid-list-xl>
            <v-layout wrap>
              <iframe
                id="info"
                width="100%"
                height="920"
                src="https://app.powerbi.com/reportEmbed?reportId=bcc1fa00-d5d5-40ad-9287-fea1df07897e&autoAuth=true&ctid=46feded0-8875-4f66-b900-ceb5282de5f0"
                frameborder="0"
                allowFullScreen="true"
              ></iframe>
            </v-layout>
          </v-container>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    </v-col>
  </v-row>
</template>

<script>

export default {
  components:{
  },
  data: () => ({
    tab: null,
  }),
  mounted() {
  },
  methods: {
  }
}
</script>