<template>
  <v-container>
    <contratos
      ref="contratos"
    >
    </contratos>
    <sucursales
      ref="sucursales"
    >
    </sucursales>
    <v-dialog
      max-width="65%"
      v-model="dialogAddEdit"
      persistent
    >
      <v-card>
        <v-card-title>
          {{ editedItem.id_cliente === -1 ? "Crear" : "Editar" }} Cliente
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="editedItem.nombre"
                label="Nombre"
                outlined
                :error="errores.nombre"
                :error-messages="errores.nombre ? 'El nombre del cliente debe tener entre 1 y 256 caracteres.' : null"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="editedItem.razon_social"
                label="Razón Social"
                outlined
                :error="errores.razon_social"
                :error-messages="errores.razon_social ? 'La razón social del cliente debe tener entre 1 y 256 caracteres.' : null"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="editedItem.numero_cliente"
                label="Número de Cliente"
                outlined
                :error="errores.numero_cliente"
                :error-messages="errores.numero_cliente ? 'El número del cliente debe tener entre 1 y 256 caracteres.' : null"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green" text @click="closeDialogAddEdit">Cancelar</v-btn>
          <v-btn color="red" text @click="saveDialogAddEdit" :loading="loadingSave">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      max-width="65%"
      v-model="dialogDelete"
      persistent
    >
      <v-card>
        <v-card-title>
          Eliminar Cliente
        </v-card-title>
        <v-card-text>
          ¿Está seguro de que desea eliminar al cliente llamado "{{ editedItem.nombre }}"? Esta acción es permanente y no se puede revertir.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green" text @click="closeDialogDelete">Cancelar</v-btn>
          <v-btn color="red" text @click="saveDialogDelete" :loading="loadingDelete">Eliminar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--
    <v-row>
      <v-col cols="12">
        <v-btn
          color="primary"
          @click="openDialogAddEdit(null)"
        >
          Crear Cliente
        </v-btn>
      </v-col>
    </v-row>
    -->
    <v-row>
      <v-col cols="12">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar"
          single-line
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :items="items"
          :headers="headers"
          :loading="loading"
          :search="search"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              small
              class="mr-2"
              @click="$refs.sucursales.openDialog(item)"
            >
              mdi-sitemap
            </v-icon>
            <v-icon
              small
              class="mr-2"
              @click="$refs.contratos.openDialog(item)"
            >
              mdi-file-document-edit-outline
            </v-icon>
            <!--
            <v-icon
              small
              class="mr-2"
              @click="openDialogAddEdit(item)"
            >
              mdi-pencil
            </v-icon>
            <v-icon
              small
              class="mr-2"
              @click="openDialogDelete(item)"
            >
              mdi-delete
            </v-icon>
            -->
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios'
import Contratos from './Contratos.vue'
import Sucursales from './Sucursales.vue'

export default {
  components:{
    Contratos,
    Sucursales
  },
  data: () => ({
    dialogAddEdit: false,
    loading: false,
    search: '',
    items: [],
    headers:[
      {
        text: "Cliente",
        value: "nombre",
        align: "center",
        width: "30%"
      },
      {
        text: "Razón Social",
        value: "razon_social",
        align: "center",
        width: "30%"
      },
      {
        text: "Número de Cliente",
        value: "numero_cliente",
        align: "center",
        width: "30%"
      },
      {
        text: "Acciones",
        value: "actions",
        align: "center",
        width: "10%"
      }
    ],
    editedItem: {
      id_cliente: -1,
      nombre: "",
      razon_social: "",
      numero_cliente: ""
    },
    defaultEditedItem: {
      id_cliente: -1,
      nombre: "",
      razon_social: "",
      numero_cliente: ""
    },
    loadingSave: false,
    errores:{
      nombre: false,
      razon_social: false,
      numero_cliente: false
    },
    defaultErrores: {
      nombre: false,
      razon_social: false,
      numero_cliente: false

    },
    dialogDelete: false,
    loadingDelete: false
  }),
  mounted() {
    this.getItems();
  },
  methods: {
    getItems(){
      this.loading = true;
      axios
      .get('/CatalogoActivos/ListarClientes')
      .then(response => {
        this.items = response.data;
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.loading = false;
      })
    },
    openDialogAddEdit(editItem = null){
      if(editItem != null) this.editedItem = Object.assign({}, editItem);
      else this.editedItem = Object.assign({}, this.defaultEditedItem);
      this.errores = Object.assign({}, this.defaultErrores);
      this.dialogAddEdit = true;
    },
    closeDialogAddEdit(){
      this.editedItem = Object.assign({}, this.defaultEditedItem);
      this.errores = Object.assign({}, this.defaultErrores);
      this.dialogAddEdit = false;
    },
    saveDialogAddEdit(){
      this.errores = Object.assign({}, this.defaultErrores);

      if (this.editedItem.nombre.length === 0 || this.editedItem.nombre.length > 256) {
        this.errores.nombre = true;
        return;
      }

      if (this.editedItem.nombre.length === 0 || this.editedItem.nombre.length > 256) {
        this.errores.nombre = true;
        return;
      }

      this.loadingSave = true;

      axios({
        method: this.editedItem.id_cliente === -1 ? 'POST' : 'PUT',
        url: this.editedItem.id_cliente === -1 ? '/CatalogoActivos/CrearCliente' : `/CatalogoActivos/EditarCliente/${this.editedItem.id_cliente}`,
        data: {
          nombre: this.editedItem.nombre,
          razon_social: this.editedItem.razon_social,
          numero_cliente: this.editedItem.numero_cliente
        }
      })
      .then(() => {
        this.getItems();
        this.closeDialogAddEdit();
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.loadingSave = false;
      });
    },
    openDialogDelete(editItem){
      this.editedItem = Object.assign({}, editItem);
      this.dialogDelete = true;
    },
    closeDialogDelete(){
      this.editedItem = Object.assign({}, this.defaultEditedItem);
      this.dialogDelete = false;
    },
    saveDialogDelete(){
      this.loadingDelete = true;
      axios
      .delete(`/CatalogoActivos/EliminarCliente/${this.editedItem.id_cliente}`)
      .then(() => {
        this.closeDialogDelete();
        this.getItems();
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.loadingDelete = false;
      });
    }
  }
}
</script>
