<template>
  <v-container>
    <v-dialog
      max-width="65%"
      v-model="dialogAddEdit"
      persistent
    >
      <v-card>
        <v-card-title>
          {{ editedItem.id_equipo_contrato == -1 ? "Crear" : "Editar" }} Asignación
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-autocomplete
                v-model="editedItem.id_cliente"
                label="Cliente"
                item-value="id_cliente"
                item-text="nombre"
                :items="clientes"
                outlined
                :error="errores.id_cliente"
                :error-messages="errores.id_cliente ? 'Debe de indicar un cliente para la asignación.' : null"
                @change="getContratos(); getSucursales();"
                :loading="loadingClientes"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="6">
              <v-autocomplete
                v-model="editedItem.id_sucursal"
                label="Sucursal"
                item-value="id_sucursal"
                :item-text="nombreSucursal"
                :items="sucursales"
                outlined
                :error="errores.id_sucursal"
                :error-messages="errores.id_sucursal ? 'Debe de indicar una sucursal para la asignación.' : null"
                :loading="loadingSucursales"
                :disabled="editedItem.id_cliente == -1"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="6">
              <v-autocomplete
                v-model="editedItem.id_contrato"
                label="Contrato"
                item-value="id_contrato"
                :item-text="nombreContrato"
                :items="contratos"
                outlined
                :error="errores.id_contrato"
                :error-messages="errores.id_contrato ? 'Debe de indicar un contrato para la asignación.' : null"
                :loading="loadingContratos"
                :disabled="editedItem.id_cliente == -1"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="6">
              <v-autocomplete
                v-model="editedItem.id_equipo"
                label="Equipo"
                item-value="id_equipo"
                :item-text="nombreEquipo"
                :items="equiposFiltrados"
                outlined
                :error="errores.id_equipo"
                :error-messages="errores.id_equipo ? 'Debe de indicar un equipo para la asignación.' : null"
                :loading="loadingEquipos"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="editedItem.consumo_mensual"
                label="Consumo Mensual"
                outlined
                @input="updateValue"   
                :error="errores.consumo_mensual"
                :error-messages="errores.consumo_mensual ? 'Debe de ingresar un monto para el consumo mensual.' : null"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green" text @click="closeDialogAddEdit">Cancelar</v-btn>
          <v-btn color="red" text @click="saveDialogAddEdit" :loading="loadingSave">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      max-width="65%"
      v-model="dialogDelete"
      persistent
    >
      <v-card>
        <v-card-title>
          Eliminar Asignación
        </v-card-title>
        <v-card-text>
          ¿Está seguro que desea eliminar la asignación llamada "{{ editedItem }}"? Esta acción es permanente y no se puede revertir.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green" text @click="closeDialogDelete">Cancelar</v-btn>
          <v-btn color="red" text @click="saveDialogDelete" :loading="loadingDelete">Eliminar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row>
      <v-col cols="12">
        <v-btn
          color="primary"
          @click="openDialogAddEdit(null)"
        >
          Crear Asignación
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :items="items"
          :headers="headers"
          :loading="loading"
        >
          <template v-slot:[`item.refurbish`]="{ item }">
            {{item.refurbish ? 'Si' : 'No'}}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              small
              class="mr-2"
              @click="openDialogAddEdit(item)"
            >
              mdi-pencil
            </v-icon>
            <v-icon
              small
              class="mr-2"
              @click="openDialogDelete(item)"
            >
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios'

export default {
  data: () => ({
    dialogAddEdit: false,
    loading: false,
    items: [],
    headers: [
      {
        text: "# Contrato",
        value: "num_contrato",
        align: "center",
        width: "7%",
      },
      {
        text: "Fecha Firma",
        value: "fecha_firma",
        align: "center",
        width: "7%",
      },
      {
        text: "Inicio Vigencia",
        value: "inicio_vigencia",
        align: "center",
        width: "7%",
      },
      {
        text: "Fin Vigencia",
        value: "fin_vigencia",
        align: "center",
        width: "7%",
      },
      {
        text: "Cliente",
        value: "cliente",
        align: "center",
        width: "7%",
      },
      {
        text: "Sucursal",
        value: "sucursal",
        align: "center",
        width: "7%",
      },
      {
        text: "Entidad",
        value: "entidad",
        align: "center",
        width: "7%",
      },
      {
        text: "Municipio",
        value: "municipio",
        align: "center",
        width: "7%",
      },
      {
        text: "Marca & Modelo",
        value: "marca_modelo",
        align: "center",
        width: "7%",
      },
      {
        text: "# Serie",
        value: "num_serie",
        align: "center",
        width: "7%",
      },
      {
        text: "Refurbish",
        value: "refurbish",
        align: "center",
        width: "7%",
      },
      {
        text: "Linea",
        value: "linea",
        align: "center",
        width: "7%",
      },
      {
        text: "Propietario",
        value: "propietario",
        align: "center",
        width: "7%",
      },
      {
        text: "Status",
        value: "status",
        align: "center",
        width: "7%",
      },
      {
        text: "Acciones",
        value: "actions",
        align: "center",
        width: "9%",
      },
    ],
    editedItem: {
      id_equipo_contrato: -1,
      id_cliente: -1,
      id_contrato: -1,
      id_sucursal: -1,
      id_equipo: -1,
      consumo_mensual: 0,
    },
    defaultEditedItem: {
      id_equipo_contrato: -1,
      id_cliente: -1,
      id_contrato: -1,
      id_sucursal: -1,
      id_equipo: -1,
      consumo_mensual: 0,
    },
    loadingSave: false,
    errores: {
      id_cliente: false,
      id_contrato: false,
      id_sucursal: false,
      id_equipo: false,
      consumo_mensual: false
    },
    defaultErrores: {
      id_cliente: false,
      id_contrato: false,
      id_sucursal: false,
      id_equipo: false,
      consumo_mensual: false
    },
    dialogDelete: false,
    loadingDelete: false,
    clientes: [],
    loadingClientes: false,
    contratos: [],
    loadingContratos: false,
    sucursales: [],
    loadingSucursales: false,
    equipos: [],
    loadingEquipos: false
  }),
  mounted() {
    this.getItems();
    this.getClientes();
    this.getEquipos();
  },
  methods: {
    getItems() {
      this.loading = true;
      axios
        .get('/CatalogoActivos/ListarAsignaciones')
        .then(response => {
          this.items = response.data;
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getClientes(){
      this.loadingClientes = true;
      axios
      .get('/CatalogoActivos/ListarClientes')
      .then(response => {
        this.clientes = response.data;
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.loadingClientes = false;
      })
    },
    getContratos() {
      this.loadingContratos = true;
      axios
        .get(`/CatalogoActivos/ListarContratos/${this.editedItem.id_cliente}`)
        .then((response) => {
          this.contratos = response.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loadingContratos = false;
        });
    },
    getSucursales() {
      this.loadingSucursales = true;
      axios
        .get(`/CatalogoActivos/ListarSucursales/${this.editedItem.id_cliente}`)
        .then((response) => {
          this.sucursales = response.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loadingSucursales = false;
        });
    },
    getEquipos(){
      this.loadingEquipos = true;
      axios
      .get('/CatalogoActivos/ListarEquipos')
      .then(response => {
        this.equipos = response.data;
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.loadingEquipos = false;
      })
    },
    openDialogAddEdit(editItem = null) {
      if (editItem != null) this.editedItem = Object.assign({}, editItem);
      else this.editedItem = Object.assign({}, this.defaultEditedItem);
      console.log(this.editedItem)
      this.errores = Object.assign({}, this.defaultErrores);
      if(this.editedItem.id_equipo_contrato != -1){
        this.getSucursales();
        this.getContratos();
      }
      this.dialogAddEdit = true;
    },
    closeDialogAddEdit() {
      this.editedItem = Object.assign({}, this.defaultEditedItem);
      this.errores = Object.assign({}, this.defaultErrores);
      this.dialogAddEdit = false;
    },
    saveDialogAddEdit() {
      this.errores = Object.assign({}, this.defaultErrores);

      if (this.editedItem.id_cliente == -1) {
        this.errores.id_cliente = true;
        return;
      }

      if (this.editedItem.id_contrato == -1) {
        this.errores.id_contrato = true;
        return;
      }

      if (this.editedItem.id_sucursal == -1) {
        this.errores.id_sucursal = true;
        return;
      }

      if (this.editedItem.id_equipo== -1) {
        this.errores.id_equipo = true;
        return;
      }

      this.loadingSave = true;
      
      axios({
        method: this.editedItem.id_equipo_contrato == -1 ? 'POST' : 'PUT',
        url: this.editedItem.id_equipo_contrato == -1 ? '/CatalogoActivos/CrearAsignacion' : `/CatalogoActivos/EditarAsignacion/${this.editedItem.id_equipo_contrato}`,
        data: {
          id_cliente: this.editedItem.id_cliente,
          id_contrato: this.editedItem.id_contrato,
          id_sucursal: this.editedItem.id_sucursal,
          id_equipo: this.editedItem.id_equipo,
          consumo_mensual: this.editedItem.consumo_mensual
        },
      })
        .then(() => {
          this.getItems();
          this.closeDialogAddEdit();
          this.getEquipos();
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.loadingSave = false;
        });
    },
    openDialogDelete(editItem) {
      this.editedItem = Object.assign({}, editItem);
      this.dialogDelete = true;
    },
    closeDialogDelete() {
      this.editedItem = Object.assign({}, this.defaultEditedItem);
      this.dialogDelete = false;
    },
    saveDialogDelete() {
      this.loadingDelete = true;
      axios
        .delete(`/CatalogoActivos/EliminarAsignacion/${this.editedItem.id_equipo_contrato}`)
        .then(() => {
          this.closeDialogDelete();
          this.getItems();
          this.getEquipos();
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.loadingDelete = false;
        });
    },
    nombreSucursal: (sucursal) => `${sucursal.nombre} - ${sucursal.municipio}, ${sucursal.entidad}`,
    nombreContrato: (contrato) => `${contrato.num_contrato} - Vigencia: ${(contrato.fin_vigencia == undefined) ? 'Sin Definir' : contrato.fin_vigencia}`,
    nombreEquipo: (equipo) => `${equipo.marca_modelo} - # Serie: ${equipo.num_serie}, Status: ${equipo.status}, Propietario: ${equipo.propietario}, Linea: ${equipo.linea}, Refurbish: ${equipo.refurbish ? "Si" : "No"}`,
    updateValue() {
      // Eliminar caracteres que no sean números y el símbolo de dólar
      const numericValue = this.editedItem.consumo_mensual.replace(/[^0-9]/g, "");

      // Formatear como dinero
      const formatted = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2,
      }).format(numericValue / 100);

      this.editedItem.consumo_mensual = formatted;
    }
  },
  computed: {
    equiposFiltrados(){
      return this.equipos.filter(e => e.id_equipo == this.editedItem.id_equipo || e.asignado == false);
    }
  }
};
</script>
