<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="90%"
  >
    <v-card>
      <v-card-title>
        Contratos de {{cliente.nombre}}
      </v-card-title>
      <v-card-text>
        <v-dialog
          max-width="65%"
          v-model="dialogAddEdit"
          persistent
        >
          <v-card>
            <v-card-title>
              {{ editedItem.id_contrato === -1 ? "Crear" : "Editar" }} Contrato
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <date-time-picker
                    v-model="editedItem.fecha_firma"
                    label="Fecha de Firma"
                    outlined
                  >
                  </date-time-picker>
                </v-col>
                <v-col cols="12">
                  <date-time-picker
                    v-model="editedItem.inicio_vigencia"
                    label="Inicio Vigencia"
                    outlined
                  >
                  </date-time-picker>
                </v-col>
                <v-col cols="12">
                  <date-time-picker
                    v-model="editedItem.fin_vigencia"
                    label="Fin Vigencia"
                    outlined
                  >
                  </date-time-picker>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="editedItem.num_contrato"
                    label="Número de Contrato"
                    outlined
                    :error="errores.nombre"
                    :error-messages="errores.nombre ? 'El nombre del contrato debe tener entre 1 y 256 caracteres.' : null"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="green" text @click="closeDialogAddEdit">Cancelar</v-btn>
              <v-btn color="red" text @click="saveDialogAddEdit" :loading="loadingSave">Guardar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
          max-width="65%"
          v-model="dialogDelete"
          persistent
        >
          <v-card>
            <v-card-title>
              Eliminar Contrato
            </v-card-title>
            <v-card-text>
              ¿Está seguro que desea eliminar el contrato llamado "{{ editedItem.nombre }}"? Esta acción es permanente y no se puede revertir.
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="green" text @click="closeDialogDelete">Cancelar</v-btn>
              <v-btn color="red" text @click="saveDialogDelete" :loading="loadingDelete">Eliminar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-row>
          <v-col cols="12">
            <v-btn
              color="primary"
              @click="openDialogAddEdit(null)"
            >
              Crear Contrato
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-data-table
              :items="items"
              :headers="headers"
              :loading="loading"
            >
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon
                  small
                  class="mr-2"
                  @click="openDialogAddEdit(item)"
                >
                  mdi-pencil
                </v-icon>
                <v-icon
                  small
                  class="mr-2"
                  @click="openDialogDelete(item)"
                >
                  mdi-delete
                </v-icon>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="green"
          text
          @click="closeDialog"
        >
          Cerrar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios'
import DateTimePicker from '@/components/DateTimePicker.vue'

export default {
  components:{
    DateTimePicker
  },
  data() {
    return {
      dialog: false,
      dialogAddEdit: false,
      loading: false,
      items: [],
      headers: [
        {
          text: "Número Contrato",
          value: "num_contrato",
          align: "center",
          width: "22.5%",
        },
        {
          text: "Fecha de Firma",
          value: "fecha_firma",
          align: "center",
          width: "22.5%",
        },
        {
          text: "Inicio Vigencia",
          value: "inicio_vigencia",
          align: "center",
          width: "22.5%",
        },
        {
          text: "Fin Vigencia",
          value: "fin_vigencia",
          align: "center",
          width: "22.5%",
        },
        {
          text: "Acciones",
          value: "actions",
          align: "center",
          width: "90%",
        },
      ],
      editedItem: {
        id_contrato: -1,
        num_contrato: "",
        fecha_firma: "",
        inicio_vigencia: "",
        fin_vigencia: ""
      },
      defaultEditedItem: {
        id_contrato: -1,
        num_contrato: "",
        fecha_firma: "",
        inicio_vigencia: "",
        fin_vigencia: ""
      },
      loadingSave: false,
      errores: {
        num_contrato: false,
        fecha_firma: false,
        inicio_vigencia: false,
        fin_vigencia: false
      },
      defaultErrores: {
        num_contrato: false,
        fecha_firma: false,
        inicio_vigencia: false,
        fin_vigencia: false
      },
      dialogDelete: false,
      loadingDelete: false,
      cliente: {
        id_cliente: -1,
        nombre: ""
      },
      defaultCliente: {
        id_cliente: -1,
        nombre: ""
      }
    };
  },
  methods: {
    openDialog(cliente) {
      this.cliente = Object.assign({}, cliente);
      this.getItems();
      this.dialog = true;
    },
    closeDialog(){
      this.cliente = Object.assign({}, this.defaultCliente);
      this.items = [];
      this.dialog = false;
    },
    getItems() {
      this.loading = true;
      axios
        .get(`/CatalogoActivos/ListarContratos/${this.cliente.id_cliente}?vigentes=true`)
        .then((response) => {
          this.items = response.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    openDialogAddEdit(editItem = null) {
      if (editItem !== null) this.editedItem = Object.assign({}, editItem);
      else this.editedItem = Object.assign({}, this.defaultEditedItem);
      this.errores = Object.assign({}, this.defaultErrores);
      this.dialogAddEdit = true;
    },
    closeDialogAddEdit() {
      this.editedItem = Object.assign({}, this.defaultEditedItem);
      this.errores = Object.assign({}, this.defaultErrores);
      this.dialogAddEdit = false;
    },
    saveDialogAddEdit() {
      this.errores = Object.assign({}, this.defaultErrores);

      if (this.editedItem.num_contrato.length === 0 || this.editedItem.num_contrato.length > 256) {
        this.errores.num_contrato = true;
        return;
      }

      this.loadingSave = true;

      axios({
        method: this.editedItem.id_contrato === -1 ? 'POST' : 'PUT',
        url: this.editedItem.id_contrato === -1 ? '/CatalogoActivos/CrearContrato' : `/CatalogoActivos/EditarContrato/${this.editedItem.id_contrato}`,
        data: {
          id_cliente: this.cliente.id_cliente,
          num_contrato: this.editedItem.num_contrato,
          fecha_firma: this.editedItem.fecha_firma,
          inicio_vigencia: this.editedItem.inicio_vigencia,
          fin_vigencia: this.editedItem.fin_vigencia
        },
      })
        .then(() => {
          this.getItems();
          this.closeDialogAddEdit();
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loadingSave = false;
        });
    },
    openDialogDelete(editItem) {
      this.editedItem = Object.assign({}, editItem);
      this.dialogDelete = true;
    },
    closeDialogDelete() {
      this.editedItem = Object.assign({}, this.defaultEditedItem);
      this.dialogDelete = false;
    },
    saveDialogDelete() {
      this.loadingDelete = true;
      axios
        .delete(`/CatalogoActivos/EliminarContrato/${this.editedItem.id_contrato}`)
        .then(() => {
          this.closeDialogDelete();
          this.getItems();
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loadingDelete = false;
        });
    },
  },
};
</script>
