<template>
  <v-container>
    <v-dialog
      max-width="65%"
      v-model="dialogAddEdit"
      persistent
    >
      <v-card>
        <v-card-title>
          {{ editedItem.id_equipo === -1 ? "Crear" : "Editar" }} Equipo
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-autocomplete
                v-model="editedItem.id_linea"
                label="Línea"
                outlined
                item-value="id_linea"
                item-text="nombre"
                :items="lineas"
                :error="errores.id_linea"
                :error-messages="errores.id_linea ? 'Debe de seleccionar una línea para el equipo.' : null"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="6">
              <v-autocomplete
                v-model="editedItem.id_propietario"
                label="Propietario"
                outlined
                item-value="id_propietario"
                item-text="nombre"
                :items="propietarios"
                :error="errores.id_propietario"
                :error-messages="errores.id_propietario ? 'Debe de seleccionar un propietario para el equipo.' : null"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="6">
              <v-autocomplete
                v-model="editedItem.id_status"
                label="Status"
                outlined
                item-value="id_status"
                item-text="nombre"
                :items="status"
                :error="errores.id_status"
                :error-messages="errores.id_status ? 'Debe de seleccionar un status para el equipo.' : null"
              >
              </v-autocomplete>
            </v-col>

            <v-col cols="6">
              <v-autocomplete
                v-model="editedItem.id_maquina"
                label="Maquina"
                outlined
                item-value="id_maquina"
                :item-text="nombreMaquina"
                :items="maquinas"
                :error="errores.id_maquina"
                :error-messages="errores.id_maquina ? 'Debe de seleccionar una maquina para el equipo.' : null"
              >
              </v-autocomplete>
            </v-col>

            <v-col cols="6">
              
              <v-text-field
                v-model="editedItem.num_serie"
                label="Número de Serie"
                outlined
                :error="errores.num_serie"
                :error-messages="errores.num_serie ? 'El número de serie del equipo debe tener entre 1 y 256 caracteres.' : null"
              >
              </v-text-field>
            </v-col>
            <v-col cols="6">
              <label>Refurbish</label>
              <v-btn-toggle
                v-model="editedItem.refurbish"
                tile
                :color="editedItem.refurbish ? 'green accent-3' : 'red accent-3'"
                group
                @change="changeRefurbish"
              >
                <v-btn :value="true">
                  Si
                </v-btn>

                <v-btn :value="false">
                  No
                </v-btn>
              </v-btn-toggle>
            </v-col>
            <v-col cols="6">
              <date-time-picker
                v-model="editedItem.fecha_compra"
                label="Fecha de Compra"
                outlined
                :error="errores.fecha_compra"
                :error-messages="errores.fecha_compra ? 'Debe de indicar una fecha de compra del equipo.' : null"
              >
              </date-time-picker>
            </v-col>
            <v-col cols="6">
              <v-autocomplete
                v-model="editedItem.vida_util"
                :items="opcionesVidaUtil"
                label="Vida Util (meses)"
                outlined
              ></v-autocomplete>
            </v-col>

            <v-col cols="6">
              <v-text-field
                v-model="editedItem.costo_compra"
                label="Costo de Compra"
                outlined
                @input="updateValue"
                :error="errores.costo_compra"
                :error-messages="errores.costo_compra ? 'Debe de ingresar un costo de compra.' : null"
              ></v-text-field>
            </v-col>

            <v-col cols="6">
              <v-text-field
                v-model="editedItem.valor_rescate"
                label="Valor de Rescate"
                outlined
                @input="updateValue2"
                :error="errores.valor_rescate"
                :error-messages="errores.valor_rescate ? 'Debe de ingresar un valor de rescate.' : null"
              ></v-text-field>
            </v-col>

          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green" text @click="closeDialogAddEdit">Cancelar</v-btn>
          <v-btn color="red" text @click="saveDialogAddEdit" :loading="loadingSave">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      max-width="65%"
      v-model="dialogDelete"
      persistent
    >
      <v-card>
        <v-card-title>
          Eliminar Equipo
        </v-card-title>
        <v-card-text>
          ¿Está seguro de que desea eliminar el equipo llamado "{{ editedItem.marca_modelo }}" con el número de serie "{{ editedItem.num_serie }}"? Esta acción es permanente y no se puede revertir.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green" text @click="closeDialogDelete">Cancelar</v-btn>
          <v-btn color="red" text @click="saveDialogDelete" :loading="loadingDelete">Eliminar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row>
      <v-col cols="12">
        <v-btn
          color="primary"
          @click="openDialogAddEdit(null)"
        >
          Crear Equipo
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :items="items"
          :headers="headers"
          :loading="loading"
        >
          <template v-slot:[`item.refurbish`]="{ item }">
            {{item.refurbish ? 'Si' : 'No'}}
          </template>
          <template v-slot:[`item.depresiacion_mensual`]="{ item }">
            {{new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 2,
              }).format(item.depresiacion_mensual)}}
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              small
              class="mr-2"
              @click="openDialogAddEdit(item)"
            >
              mdi-pencil
            </v-icon>
            <v-icon
              small
              class="mr-2"
              @click="openDialogDelete(item)"
            >
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios'
import DateTimePicker from '@/components/DateTimePicker.vue'

export default {
  components: {
    DateTimePicker
  },
  data: () => ({
    dialogAddEdit: false,
    loading: false,
    items: [],
    headers:[
      {
        text: "Marca y Modelo",
        value: "marca_modelo",
        align: "center",
        width: "10.5%"
      },
      {
        text: "Número Serie",
        value: "num_serie",
        align: "center",
        width: "10.5%"
      },
      {
        text: "Linea",
        value: "linea",
        align: "center",
        width: "10.5%"
      },
      {
        text: "Propietario",
        value: "propietario",
        align: "center",
        width: "10.5%"
      },
      {
        text: "Status",
        value: "status",
        align: "center",
        width: "10.5%"
      },
      {
        text: "Refurbish",
        value: "refurbish",
        align: "center",
        width: "10.5%"
      },
      {
        text: "Fecha Compra",
        value: "fecha_compra",
        align: "center",
        width: "10.5%"
      },
      {
        text: "Vida Util (meses)",
        value: "vida_util",
        align: "center",
        width: "10.5%"
      },
      {
        text: "Costo Compra",
        value: "costo_compra",
        align: "center",
        width: "10.5%"
      },
      {
        text: "Valor Rescate",
        value: "valor_rescate",
        align: "center",
        width: "10.5%"
      },
      {
        text: "Depresiación Mensual",
        value: "depresiacion_mensual",
        align: "center",
        width: "10.5%"
      },
      {
        text: "Acciones",
        value: "actions",
        align: "center",
        width: "5.5%"
      }
    ],
    editedItem: {
      id_equipo: -1,
      id_linea: -1,
      id_propietario: -1,
      id_status: -1,
      id_maquina: -1,
      num_serie: "",
      refurbish: false,
      fecha_compra: "",
      vida_util: -1,
      costo_compra: "$1,000,000.00",
      valor_rescate: "$200,000.00",
    },
    defaultEditedItem: {
      id_equipo: -1,
      id_linea: -1,
      id_propietario: -1,
      id_status: -1,
      id_maquina: -1,
      num_serie: "",
      refurbish: false,
      fecha_compra: "",
      vida_util: -1,
      costo_compra: "$1,000,000.00",
      valor_rescate: "$200,000.00",
    },
    loadingSave: false,
    errores:{
      id_linea: false,
      id_propietario: false,
      id_status: false,
      id_equipo: false,
      num_serie: false,
      fecha_compra: false,
      vida_util: false,
      costo_compra: false,
      valor_rescate: false,
    },
    defaultErrores: {
      id_linea: false,
      id_propietario: false,
      id_status: false,
      id_equipo: false,
      num_serie: false,
      fecha_compra: false,
      vida_util: false,
      costo_compra: false,
      valor_rescate: false,
    },
    dialogDelete: false,
    loadingDelete: false,
    lineas: [],
    loadingLineas: false,
    propietarios: [],
    loadingPropietarios: false,
    status: [],
    loadingStatus: false,
    maquinas: [],
    loadingMaquinas: false,
    opcionesVidaUtil: [12, 24, 32, 48, 60],
  }),
  mounted() {
    this.getItems();
    this.getLineas();
    this.getPropietarios();
    this.getStatus();
    this.getMaquinas();
  },
  methods: {
    nombreMaquina: (maquina) => `${maquina.marca} ${maquina.modelo}`,
    getItems(){
      this.loading = true;
      axios
      .get('/CatalogoActivos/ListarEquipos')
      .then(response => {
        this.items = response.data;
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.loading = false;
      })
    },
    getLineas(){
      this.loadingLineas = true;
      axios
      .get('/CatalogoActivos/ListarLineas')
      .then(response => {
        this.lineas = response.data;
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.loadingLineas = false;
      })
    },
    getPropietarios(){
      this.loadingPropietarios = true;
      axios
      .get('/CatalogoActivos/ListarPropietarios')
      .then(response => {
        this.propietarios = response.data;
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.loadingPropietarios = false;
      })
    },
    getStatus(){
      this.loadingStatus = true;
      axios
      .get('/CatalogoActivos/ListarStatus')
      .then(response => {
        this.status = response.data;
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.loadingStatus = false;
      })
    },
    openDialogAddEdit(editItem = null){
      if(editItem != null) this.editedItem = Object.assign({}, editItem);
      else this.editedItem = Object.assign({}, this.defaultEditedItem);
      this.errores = Object.assign({}, this.defaultErrores);
      this.dialogAddEdit = true;
    },
    closeDialogAddEdit(){
      this.editedItem = Object.assign({}, this.defaultEditedItem);
      this.errores = Object.assign({}, this.defaultErrores);
      this.dialogAddEdit = false;
    },
    saveDialogAddEdit(){
      this.errores = Object.assign({}, this.defaultErrores);

      if (this.editedItem.num_serie.length === 0 || this.editedItem.num_serie.length > 256) {
        this.errores.num_serie = true;
        return;
      }

      if (this.editedItem.id_linea == -1) {
        this.errores.id_linea = true;
        return;
      }

      if (this.editedItem.id_propietario == -1) {
        this.errores.id_propietario = true;
        return;
      }

      if (this.editedItem.id_status == -1) {
        this.errores.id_status = true;
        return;
      }

      if (this.editedItem.id_maquina == -1) {
        this.errores.id_maquina = true;
        return;
      }

      if (this.editedItem.fecha_compra == null || this.editedItem.fecha_compra == "" || this.editedItem.fecha_compra.length != 10) {
        this.errores.fecha_compra = true;
        return;
      }

      if (this.editedItem.vida_util <= 0) {
        this.errores.vida_util = true;
        return;
      }

      if (this.editedItem.costo_compra < 0) {
        this.errores.vida_util = true;
        return;
      }

      if (this.editedItem.valor_rescate < 0) {
        this.errores.vida_util = true;
        return;
      }

      this.loadingSave = true;

      axios({
        method: this.editedItem.id_equipo === -1 ? 'POST' : 'PUT',
        url: this.editedItem.id_equipo === -1 ? '/CatalogoActivos/CrearEquipo' : `/CatalogoActivos/EditarEquipo/${this.editedItem.id_equipo}`,
        data: {
          id_linea: this.editedItem.id_linea,
          id_propietario: this.editedItem.id_propietario,
          id_status: this.editedItem.id_status,
          id_maquina: this.editedItem.id_maquina,
          num_serie: this.editedItem.num_serie,
          refurbish: this.editedItem.refurbish,
          fecha_compra: this.editedItem.fecha_compra,
          vida_util: this.editedItem.vida_util,
          costo_compra: this.editedItem.costo_compra,
          valor_rescate: this.editedItem.valor_rescate
        }
      })
      .then(() => {
        this.getItems();
        this.closeDialogAddEdit();
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.loadingSave = false;
      });
    },
    openDialogDelete(editItem){
      this.editedItem = Object.assign({}, editItem);
      this.dialogDelete = true;
    },
    closeDialogDelete(){
      this.editedItem = Object.assign({}, this.defaultEditedItem);
      this.dialogDelete = false;
    },
    saveDialogDelete(){
      this.loadingDelete = true;
      axios
      .delete(`/CatalogoActivos/EliminarEquipo/${this.editedItem.id_equipo}`)
      .then(() => {
        this.closeDialogDelete();
        this.getItems();
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.loadingDelete = false;
      });
    },
    getMaquinas(){
      this.loadingMaquinas = true;
      axios
      .get('/CatalogoActivos/ListarMaquinas')
      .then(response => {
        this.maquinas = response.data;
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.loadingMaquinas = false;
      })
    },
    updateValue() {
      // Eliminar caracteres que no sean números y el símbolo de dólar
      const numericValue = this.editedItem.costo_compra.replace(/[^0-9]/g, "");

      // Formatear como dinero
      const formatted = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2,
      }).format(numericValue / 100);

      this.editedItem.costo_compra = formatted;

      const valor_rescate = numericValue * 0.2;
      this.editedItem.valor_rescate = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2,
      }).format(valor_rescate / 100);
    },
    updateValue2() {
      // Eliminar caracteres que no sean números y el símbolo de dólar
      const numericValue = this.editedItem.valor_rescate.replace(/[^0-9]/g, "");

      // Formatear como dinero
      const formatted = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2,
      }).format(numericValue / 100);

      this.editedItem.valor_rescate = formatted;
    },
    changeRefurbish(){
      if(this.editedItem.refurbish){
        this.editedItem.costo_compra = "$500,000.00"
        this.editedItem.valor_rescate = "$100,000.00"
      }
      else {
        this.editedItem.costo_compra = "$1,000,000.00"
        this.editedItem.valor_rescate = "$200,000.00"
      }
    }
  }
}
</script>
