<template>
  <v-container>
    <v-dialog
      max-width="65%"
      v-model="dialogAddEdit"
      persistent
    >
      <v-card>
        <v-card-title>
          {{ editedItem.id_presupuesto === -1 ? "Crear" : "Editar" }} Presupuesto
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-autocomplete
                v-model="editedItem.id_vendedor"
                label="Vendedor"
                outlined
                item-value="id_vendedor"
                item-text="nombre"
                :items="vendedores"
                :error="errores.id_vendedor"
                :error-messages="errores.id_vendedor ? 'Debe de seleccionar un vendedor para el presupuesto.' : null"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="6">
              <v-autocomplete
                v-model="editedItem.anhio"
                :items="years"
                label="Año"
                outlined
                @change="updateMonths"
                :error="errores.anhio"
                :error-messages="errores.anhio ? 'Debe de seleccionar un año para el presupuesto.' : null"
              ></v-autocomplete>
            </v-col>
            <v-col cols="6">
              <v-autocomplete
                v-model="editedItem.mes"
                :items="mesesFiltrados"
                label="Mes"
                outlined
                :error="errores.mes"
                :error-messages="errores.mes ? 'Debe de seleccionar un mes para el presupuesto.' : null"
              ></v-autocomplete>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="editedItem.monto"
                label="Monto"
                outlined
                @input="updateValue"
                :error="errores.monto"
                :error-messages="errores.monto ? 'Debe de ingresar un monto para el presupuesto.' : null"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green" text @click="closeDialogAddEdit">Cancelar</v-btn>
          <v-btn color="red" text @click="saveDialogAddEdit" :loading="loadingSave">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      max-width="65%"
      v-model="dialogDelete"
      persistent
    >
      <v-card>
        <v-card-title>
          Eliminar Presupuesto
        </v-card-title>
        <v-card-text>
          ¿Está seguro de que desea eliminar el presupuesto llamado "{{ editedItem.nombre }}"? Esta acción es permanente y no se puede revertir.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green" text @click="closeDialogDelete">Cancelar</v-btn>
          <v-btn color="red" text @click="saveDialogDelete" :loading="loadingDelete">Eliminar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      max-width="85%"
      v-model="dialogYear"
      persistent
    >
      <v-card>
        <v-card-title>
          Editar Presupuesto Anual
        </v-card-title>
        <v-card-text>
          <v-row class="ma-0 pa-0">
            <v-col cols="6">
              <v-autocomplete
                v-model="filterYear.anhio"
                :items="years"
                label="Año"
                outlined
                @change="getItemsYear"
                :loading="loadingYear"
                :error="erroresYear.anhio"
                :error-messages="erroresYear.anhio ? 'Debe de seleccionar un año para el presupuesto.' : null"
              ></v-autocomplete>
            </v-col>
            <v-col cols="6">
              <v-autocomplete
                v-model="filterYear.id_vendedor"
                label="Vendedor"
                outlined
                item-value="id_vendedor"
                item-text="nombre"
                :items="vendedores"
                @change="getItemsYear"
                :loading="loadingYear"
                :error="erroresYear.id_vendedor"
                :error-messages="erroresYear.id_vendedor ? 'Debe de seleccionar un vendedor para el presupuesto.' : null"
              >
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row class="ma-0 pa-0">
            <v-col cols="12">
              <v-data-table
                :items="itemsYear"
                :headers="headersYear"
                hide-default-footer
                :items-per-page="-1"
                :loading="loadingYear"
                no-data-text="Indique un Año y Vendedor para mostrar los presupuestos asignados"
              >
                <template v-slot:[`item.monto`]="{ item }">
                  <v-text-field
                  v-model="item.monto"
                  :label="`Monto - ${item.mesNombre}`"
                  outlined
                  hide-details
                  dense
                ></v-text-field>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green" text @click="closeDialogYear">Cancelar</v-btn>
          <v-btn color="red" text @click="savePresupuestoAnhio" :loading="loadingYear">Actualizar Presupuesto</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row>
      <v-col cols="3">
        <v-btn
          color="primary"
          @click="openDialogAddEdit(null)"
        >
          Crear Presupuesto
        </v-btn>
      </v-col>
      <v-col cols="6">

      </v-col>
      <v-col cols="3">
        <v-btn
          color="primary"
          @click="openDialogYear()"
        >
          Modificar Presupuesto Anual
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :items="items"
          :headers="headers"
          :loading="loading"
        >
          <template v-slot:[`item.mes`]="{ item }">
            {{ months[item.mes-1].text }}
            
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              small
              class="mr-2"
              @click="openDialogAddEdit(item)"
            >
              mdi-pencil
            </v-icon>
            <v-icon
              small
              class="mr-2"
              @click="openDialogDelete(item)"
            >
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios'

export default {
  data: () => ({
    dialogAddEdit: false,
    loading: false,
    items: [],
    headers:[
      {
        text: "Año",
        value: "anhio",
        align: "center",
        width: "22%"
      },
      {
        text: "Mes",
        value: "mes",
        align: "center",
        width: "22%"
      },
      {
        text: "Vendedor",
        value: "vendedor",
        align: "center",
        width: "22%"
      },
      {
        text: "Monto",
        value: "monto",
        align: "center",
        width: "22%"
      },
      {
        text: "Acciones",
        value: "actions",
        align: "center",
        width: "11%"
      }
    ],
    editedItem: {
      id_presupuesto: -1,
      id_vendedor: -1,
      anhio: -1,
      mes: -1,
      monto: 0,
    },
    defaultEditedItem: {
      id_presupuesto: -1,
      id_vendedor: -1,
      anhio: -1,
      mes: -1,
      monto: 0,
    },
    loadingSave: false,
    errores:{
      id_vendedor: false,
      anhio: false,
      mes: false,
      monto: false
    },
    defaultErrores: {
      id_vendedor: false,
      anhio: false,
      mes: false,
      monto: false
    },
    dialogDelete: false,
    loadingDelete: false,
    vendedores: [],
    loadingVendedores: false,
    years: [],
    months: [
      {
        value: 1,
        text: "Enero"
      },
      {
        value: 2,
        text: "Febrero"
      },
      {
        value: 3,
        text: "Marzo"
      },
      {
        value: 4,
        text: "Abril"
      },
      {
        value: 5,
        text: "Mayo"
      },
      {
        value: 6,
        text: "Junio"
      },
      {
        value: 7,
        text: "Julio"
      },
      {
        value: 8,
        text: "Agosto"
      },
      {
        value: 9,
        text: "Septiembre"
      },
      {
        value: 10,
        text: "Octubre"
      },
      {
        value: 11,
        text: "Noviembre"
      },
      {
        value: 12,
        text: "Diciembre"
      }
    ],
    defaultFilterYear:{
      id_vendedor: -1,
      anhio: 0,
    },
    filterYear:{
      id_vendedor: -1,
      anhio: 0,
    },
    dialogYear: false,
    erroresYear:{
      id_vendedor: false,
      anhio: false,
    },
    defaultErroresYear: {
      id_vendedor: false,
      anhio: false,
    },
    headersYear:[
      {
        text: "Mes",
        value: "mesNombre",
        align: "center",
        width: "50%",
        sortable: false
      },
      {
        text: "Monto",
        value: "monto",
        align: "center",
        width: "50%",
        sortable: false
      }
    ],
    itemsYear:[],
    loadingYear: false
  }),
  computed: {
    currentYear() {
      return new Date().getFullYear();
    },
    mesesFiltrados(){
      const currentMonth = new Date().getMonth() + 1;
      if (this.editedItem.anhio == this.currentYear) {
        return this.months.filter(m => m.value <= currentMonth);
      } else {
        return this.months;
      }
    }
  },
  created() {
    const startYear = 2019;
    const endYear = this.currentYear+1;

    this.years = Array.from(
      { length: endYear - startYear + 1 },
      (_, i) => startYear + i
    );
  },
  mounted() {
    this.getItems();
    this.getVendedores();
  },
  methods: {
    getItems(){
      this.loading = true;
      axios
      .get('/CatalogoActivos/ListarPresupuestos')
      .then(response => {
        this.items = response.data;
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.loading = false;
      })
    },
    openDialogYear(){
      this.dialogYear = true;
      this.filterYear = Object.assign({}, this.defaultFilterYear);
      this.erroresYear = Object.assign({}, this.defaultErroresYear);
      this.itemsYear = [];
      this.loadingYear = false;
    },
    closeDialogYear(){
      this.dialogYear = false;
      this.filterYear = Object.assign({}, this.defaultFilterYear);
      this.erroresYear = Object.assign({}, this.defaultErroresYear);
      this.itemsYear = [];
      this.loadingYear = false;
    },
    getItemsYear(){
      let error = false;
      this.erroresYear = Object.assign({}, this.defaultErroresYear);

      if(this.filterYear.id_vendedor <= 0) {
        this.erroresYear.id_vendedor = true;
        error = true;
      }

      if(this.filterYear.anhio <= 0) {
        this.erroresYear.anhio = true;
        error = true;
      }

      if(error) return;

      this.loadingYear = true;
      axios
      .get(`/CatalogoActivos/ListarPresupuestosAnhio/${this.filterYear.anhio}/${this.filterYear.id_vendedor}`)
      .then(response => {
        this.itemsYear = response.data;
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.loadingYear = false;
      })
    },
    savePresupuestoAnhio(){
      this.loadingYear = true;

      var data = this.itemsYear.map(i => {
        return {
          mes: i.mes,
          monto: i.monto
        }
      })

      axios
      .post(`/CatalogoActivos/GuardarPresupuestosAnhio/${this.filterYear.anhio}/${this.filterYear.id_vendedor}`,data)
      .then(() => {
        this.getItems();
        this.closeDialogYear();
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.loadingYear = false;
      })
    },
    openDialogAddEdit(editItem = null){
      if(editItem != null) this.editedItem = Object.assign({}, editItem);
      else this.editedItem = Object.assign({}, this.defaultEditedItem);
      this.errores = Object.assign({}, this.defaultErrores);
      this.dialogAddEdit = true;
    },
    closeDialogAddEdit(){
      this.editedItem = Object.assign({}, this.defaultEditedItem);
      this.errores = Object.assign({}, this.defaultErrores);
      this.dialogAddEdit = false;
    },
    saveDialogAddEdit(){
      this.errores = Object.assign({}, this.defaultErrores);

      if (this.editedItem.id_vendedor == -1) {
        this.errores.id_vendedor = true;
        return;
      }

      if (this.editedItem.anhio == -1) {
        this.errores.anhio = true;
        return;
      }

      if (this.editedItem.mes == -1) {
        this.errores.mes = true;
        return;
      }

      if (this.editedItem.monto < 0) {
        this.errores.monto = true;
        return;
      }

      this.loadingSave = true;

      axios({
        method: this.editedItem.id_presupuesto === -1 ? 'POST' : 'PUT',
        url: this.editedItem.id_presupuesto === -1 ? '/CatalogoActivos/CrearPresupuesto' : `/CatalogoActivos/EditarPresupuesto/${this.editedItem.id_presupuesto}`,
        data: {
          id_vendedor: this.editedItem.id_vendedor,
          anhio: this.editedItem.anhio,
          mes: this.editedItem.mes,
          monto: this.editedItem.monto
        }
      })
      .then(() => {
        this.getItems();
        this.closeDialogAddEdit();
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.loadingSave = false;
      });
    },
    openDialogDelete(editItem){
      this.editedItem = Object.assign({}, editItem);
      this.dialogDelete = true;
    },
    closeDialogDelete(){
      this.editedItem = Object.assign({}, this.defaultEditedItem);
      this.dialogDelete = false;
    },
    saveDialogDelete(){
      this.loadingDelete = true;
      axios
      .delete(`/CatalogoActivos/EliminarPresupuesto/${this.editedItem.id_presupuesto}`)
      .then(() => {
        this.closeDialogDelete();
        this.getItems();
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.loadingDelete = false;
      });
    },
    getVendedores(){
      this.loadingVendedores = true;
      axios
      .get('/CatalogoActivos/ListarVendedores')
      .then(response => {
        this.vendedores = response.data;
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.loadingVendedores = false;
      })
    },
    updateMonths() {
      this.editedItem.mes = -1;
    },
    updateValue() {
      // Eliminar caracteres que no sean números y el símbolo de dólar
      const numericValue = this.editedItem.monto.replace(/[^0-9]/g, "");

      // Formatear como dinero
      const formatted = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2,
      }).format(numericValue / 100);

      this.editedItem.monto = formatted;
    },
  }
}
</script>
