<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :return-value.sync="date"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-combobox
        v-model="date"
        :label="label"
        :outlined="outlined"
        :error="error"
        :error-messages="errorMessage"
        readonly
        v-bind="attrs"
        v-on="on"
      ></v-combobox>
    </template>
    <v-date-picker
      v-model="date"
      no-title
      scrollable
      @input="updateValue"
    >
      <v-spacer></v-spacer>
      <v-btn
        text
        color="primary"
        @click="menu = false"
      >
        Cancelar
      </v-btn>
      <v-btn
        text
        color="primary"
        @click="$refs.menu.save(date)"
      >
        Aceptar
      </v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
export default {
  props: {
    value:{
      type:String,
      default:null,
    },
    label: {
			type: String,
			default: null,
		},
    outlined: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: null
    }
  },
  data: () => ({
    date: null,
    menu: false
  }),
  mounted() {
    this.date = this.value;
  },
  methods: {
    updateValue(newValue) {
      this.$emit('input', newValue);
    }
  },
  watch: {
    value(newValue) {
      this.date = newValue;
    },
  },
}
</script>