<template>
  <v-container>
    <v-dialog
      v-model="dialogo"
      max-width="600px"
      persistent
    >
      <v-card>
        <v-card-title>
          {{ editedGateway.id_gateway == -1 ? "Nuevo" : "Editar" }} Gateway
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <p class="ma-0">Nombre del Gateway</p>
              <v-text-field
                id="name"
                v-model="editedGateway.name"
                hide-details
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <p class="ma-0">Código del Gateway</p>
              <v-text-field
                id="code"
                v-model="editedGateway.code"
                hide-details
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <p class="ma-0">Contraseña del Gateway</p>
              <v-text-field
                v-if="!editedGateway.passwordActiva"
                hide-details
                outlined
                dense
                type="password"
                value="1234567812345678"
                :append-icon="'mdi-lock-open-outline'"
                readonly
                @click:append="editedGateway.passwordActiva = true"
              ></v-text-field>
              <v-text-field
                id="password"
                v-else
                v-model="editedGateway.password"
                :append-icon="editedGateway.mostrarPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="editedGateway.mostrarPassword ? 'text' : 'password'"
                dense
                hide-details
                outlined
                @click:append="editedGateway.mostrarPassword = !editedGateway.mostrarPassword"
            ></v-text-field>
            </v-col>
            <v-col cols="12">
              <p class="ma-0">Tiempo Entre Sincronizaciones (Mins)</p>
              <vue-number-input
                v-model="editedGateway.time_sync_minutes"
                :min="0"
                controls
                center
                size="small"
              ></vue-number-input>
            </v-col>
            <v-col v-if="errores.length > 0" cols="12">
              <ul>
                <li v-for="(error, idx) in errores" :key="'error-'+idx" class="red--text">{{error}}</li>
              </ul>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :loading="saving"
            :disabled="saving"
            color="green"
            text
            @click="closeDialog"
          >
            Cerrar
          </v-btn>
          <v-btn
            :loading="saving"
            :disabled="saving"
            color="red"
            text
            @click="saveGateway"
          >
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogoEliminar"
      max-width="600px"
      persistent
    >
      <v-card>
        <v-card-title>
          Eliminar Gateway
        </v-card-title>
        <v-card-text>
          ¿Esta seguro que desea eliminar este Gateway?
          <br>
          <b>Código: </b> {{gatewayDeleted.code}}
          <br>
          <b>Nombre: </b> {{gatewayDeleted.name}}
          <br>
          <b>Tiempo Entre Sincronizaciones (Mins): </b> {{gatewayDeleted.time_sync_minutes}}
          <br>
          <b>Ultima Sincronización: </b> {{gatewayDeleted.last_sync}}
          <br>
          <b># Tablas: </b> {{gatewayDeleted.num_tables}}
          <br>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :loading="deleting"
            :disabled="deleting"
            color="green"
            text
            @click="closeDialogDelete"
          >
            Cerrar
          </v-btn>
          <v-btn
            :loading="deleting"
            :disabled="deleting"
            color="red"
            text
            @click="deleteGateway"
          >
            Eliminar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-col cols="12">
      <v-btn
        color="primary"
        @click="openDialog(null)"
      >Agregar Gateway
      </v-btn>
    </v-col>
    <v-col cols="12">
      <v-data-table
        :headers="headers"
        :items="gateways"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            small
            class="mr-2"
            @click="openDialog(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
            small
            @click="openDialogDelete(item)"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-col>
  </v-container>
</template>

<script>
import axios from 'axios'


export default {
  components:{
  },
  data: () => ({
    defaultGateway:{
      id_gateway: -1,
      name: "",
      code: "",
      password: "",
      time_sync_minutes: 0,
      passwordActiva: true,
      mostrarPassword: false
    },
    editedGateway:{
      id_gateway: -1,
      name: "",
      code: "",
      password: "",
      time_sync_minutes: 0,
      passwordActiva: true,
      mostrarPassword: false
    },
    loading: false,
    saving: false,
    errores: [],
    gateways: [],
    headers: [
      { text: 'Código', value: 'code', align: 'center' },
      { text: 'Nombre', value: 'name', align: 'center' },
      { text: 'Tiempo Entre Sincronizaciones (Mins)', value: 'time_sync_minutes', align: 'center' },
      { text: 'Ultima Sincronización', value: 'last_sync', align: 'center' },
      { text: '# Tablas', value: 'num_tables', align: 'center' },
      { text: 'Acciones', value: 'actions', align: 'center', sortable: false },
    ],
    dialogo: false,
    dialogoEliminar: false,
    defaultGatewayDeleted:{
      id_gateway: -1,
      name: "",
      code: "",
      password: "",
      time_sync_minutes: 0
    },
    gatewayDeleted:{
      id_gateway: -1,
      name: "",
      code: "",
      password: "",
      time_sync_minutes: 0
    },
    deleting: false,
  }),
  mounted() {
    this.getGateways();
  },
  methods: {
    openDialog(item = null){
      this.dialogo = true;
      if(item != null) this.editedGateway = {
        ...item,
        passwordActiva: false,
        mostrarPassword: false
      };
      else this.editedGateway = Object.assign({}, this.defaultGateway);
    },
    closeDialog(){
      this.dialogo = false;
      this.errores = [];
      this.editedGateway = Object.assign({}, this.defaultGateway);
    },
    getGateways(){
      this.loading = true;
      axios
      .get('/gateway/list')
      .then(response => {
        this.gateways = response.data;
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        this.loading = false;
      })
    },
    isValidForm(){
      this.errores = [];
      if(this.editedGateway.name == null || this.editedGateway.name.length < 4) this.errores.push("El Nombre del Gateway debe tener al menos de 4 caracteres");
      if(this.editedGateway.code == null || this.editedGateway.code.length < 4) this.errores.push("El Código del Gateway debe tener al menos de 4 caracteres");
      if((this.editedGateway.id_gateway == -1 || this.editedGateway.passwordActiva) && (this.editedGateway.password == null || this.editedGateway.password.length < 8)) this.errores.push("La Password del Gateway debe tener al menos de 8 caracteres");

      return this.errores.length == 0;
    },
    saveGateway(){
      if(!this.isValidForm()) return;

      if(this.editedGateway.id_gateway == -1){
        this.saving = true;
        axios
        .post("/gateway/create", {
          name: this.editedGateway.name,
          code: this.editedGateway.code,
          password: this.editedGateway.password,
          time_sync_minutes: this.editedGateway.time_sync_minutes
        })
        .then(() => {
          this.closeDialog();
          this.getGateways();
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.saving = false;
        })
      }
      else{
        this.saving = true;
        axios
        .put("/gateway/update", {
          id_gateway: this.editedGateway.id_gateway,
          name: this.editedGateway.name,
          code: this.editedGateway.code,
          password: this.editedGateway.passwordActiva ? this.editedGateway.password : null,
          time_sync_minutes: this.editedGateway.time_sync_minutes
        })
        .then(() => {
          this.closeDialog();
          this.getGateways();
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.saving = false;
        })
      }
    },
    openDialogDelete(item){
      this.gatewayDeleted = item;
      this.dialogoEliminar = true;
    },
    closeDialogDelete(){
      this.gatewayDeleted = Object.assign({}, this.defaultGatewayDeleted);
      this.dialogoEliminar = false;
      this.deleting = false;
    },
    deleteGateway(){
      this.deleting = true;
      axios
      .post("/gateway/delete/"+this.gatewayDeleted.id_gateway)
      .then(() => {
        this.closeDialogDelete();
        this.getGateways();
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        this.deleting = false;
      })
    }
  }
}
</script>