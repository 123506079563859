// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'

// Components
import './components'

// Sync router with store
import { sync } from 'vuex-router-sync'

// Application imports
import App from './App.vue'
import router from '@/router'
import store from '@/store'
import Vuetify from 'vuetify'
import es from 'vuetify/es5/locale/es'
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import 'vue-select/dist/vue-select.css';
import axios from 'axios'
import vSelect from 'vue-select'
import VueNumberInput from '@chenfengyuan/vue-number-input';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';


Vue.prototype.$http = axios

// Sets the default url used by all of this axios instance's requests
axios.defaults.baseURL = "/api";
axios.defaults.headers.get['Accept'] = 'application/json'

const token = localStorage.getItem('token')
if (token) {
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
}

// Sync store with router
sync(store, router)

Vue.use(Vuetify)
Vue.component('v-select-advance', vSelect)
Vue.component('vue-number-input', VueNumberInput);
Vue.use(VueSweetalert2);

axios.interceptors.response.use(function (response) {
  if(store.state.netError == true) store.state.netError = false;
  return response;
}, async function (error) {
  if (!error.response) {
    if(store.state.netError == false){
      store.state.netError = true;
    }
    
  } 
  const originalRequest = error.config;
  if (401 === error.response.status && !originalRequest._retry) {
    await store.dispatch('refreshtoken');
    originalRequest._retry = true;
    originalRequest.headers['Authorization'] = 'Bearer ' + store.state.token;
    originalRequest.baseURL = undefined;
    return Promise.resolve(await axios(originalRequest));
  }
  else if(403 === error.response.status) {
    store.dispatch('logout');
    router.go();
    return Promise.reject(error);
  } else {
      return Promise.reject(error);
  }
});

const vuetifyOpts = {
  lang: {
    locales: { es },
    current: 'es',
  }
}
Vue.config.productionTip = false

new Vue({
  el: '#app',
  router,
  store,
  components: { App },
  render: h => h(App),
  template: '<App/>',
  vuetify: new Vuetify(vuetifyOpts)
})

